import React, { Component } from "react";
import {
  Button,
  Form,
  Header,
  Divider,
  List,
  Icon,
  Segment,
  Message
} from "semantic-ui-react";
import request from "request-promise";
import check from "check-types";
import moment from "moment";
import queryString from "query-string";
import isValidEmail from "is-valid-email";

export default class App extends Component {
  constructor(...args) {
    super(...args);
    this.state = {};

    if (window && check.nonEmptyString(window.location.search)) {
      const query = queryString.parse(window.location.search);
      if (check.nonEmptyString(query.email)) {
        this.state.email = query.email;
      }
    }
  }

  apiKey() {
    return check.nonEmptyString(window.location.hash)
      ? window.location.hash.substr(1)
      : "none";
  }

  async componentDidMount() {
    const { email } = this.state;

    if (check.nonEmptyString(email) && isValidEmail(email)) {
      await this.fetchSubscriptionsForEmail(email);
    }
  }

  async fetchSubscriptionsForEmail(email) {
    const endpoint = "/api/get-subscriptions";

    try {
      const response = await request({
        uri: "https://" + window.location.host + endpoint,
        json: true,
        qs: {
          apiKey: this.apiKey(),
          email
        }
      });

      if (check.nonEmptyString(response.error)) {
        this.setState({ error: response.error, subscriptions: null });
        return;
      }

      if (check.array(response.subscriptions)) {
        const { payments = [] } = response;
        this.setState({ subscriptions: response.subscriptions, payments });
        return;
      }

      this.setState({
        error: "Strange error received from server",
        subscriptions: null
      });
    } catch (err) {
      this.setState({ error: err.message, subscriptions: null });
    }
  }

  async cancelSubscription(email, subscriptionId) {
    const endpoint = "/api/cancel-subscription";

    try {
      const response = await request({
        uri: "https://" + window.location.host + endpoint,
        json: true,
        qs: {
          apiKey: this.apiKey(),
          email,
          subscriptionId
        }
      });

      if (!response.success) {
        this.setState({ error: "Failed to cancel subscription. Try again?" });
        return;
      } else {
        await this.fetchSubscriptionsForEmail(email);
      }
    } catch (err) {
      this.setState({ error: err.message });
    }
  }

  async refundPayment(email, paymentId) {
    const endpoint = "/api/refund-payment";

    try {
      const response = await request({
        uri: "https://" + window.location.host + endpoint,
        json: true,
        qs: {
          apiKey: this.apiKey(),
          email,
          paymentId
        }
      });

      if (!response.success) {
        this.setState({ error: "Failed to refund payment. Try again?" });
        return;
      } else {
        await this.fetchSubscriptionsForEmail(email);
      }
    } catch (err) {
      this.setState({ error: err.message });
    }
  }

  render() {
    const { email, subscriptions = null, payments = null, error } =
      this.state || {};
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100vh",
          width: "100vw",
          padding: 0,
          margin: 0
        }}
      >
        <Segment style={{ margin: "5em", minWidth: "80%" }}>
          <Form
            onSubmit={() => {
              this.fetchSubscriptionsForEmail(email);
            }}
          >
            <Header as="h3">User e-mail</Header>
            <Form.Input
              type="email"
              name="email"
              value={email}
              onChange={(e, d) => {
                const em = d.value;
                this.setState({ email: em });
              }}
            />
            <Button type="submit">Go</Button>
          </Form>
          <Divider />
          {error && (
            <Message negative>
              <Message.Header>Error:</Message.Header>
              <p>{error}</p>
            </Message>
          )}
          {subscriptions && subscriptions.length === 0 && (
            <p>No subscriptions found for this email</p>
          )}
          {subscriptions && subscriptions.length > 0 && (
            <List divided verticalAlign="middle">
              {subscriptions.map(s => (
                <List.Item key="s.id" verticalAlign="middle">
                  <List.Content floated="right" verticalAlign="middle">
                    <Button
                      negative
                      disabled={!s.active}
                      onClick={() => {
                        this.cancelSubscription(email, s.gatewaySubscriptionId);
                      }}
                    >
                      Cancel
                    </Button>
                  </List.Content>
                  <Icon name="gem" />
                  <List.Content verticalAlign="middle">
                    {(!s.active ? "[Canceled] " : "") + s.name}
                  </List.Content>
                </List.Item>
              ))}
            </List>
          )}
          <Divider />
          {payments && payments.length > 0 && (
            <List divided verticalAlign="middle">
              {payments
                .filter(p => p.isPaid)
                .map(p => (
                  <List.Item key="s.id" verticalAlign="middle">
                    <List.Content floated="right" verticalAlign="middle">
                      <Button
                        primary
                        disabled={p.isRefunded}
                        onClick={() => {
                          this.refundPayment(email, p.id);
                        }}
                      >
                        Refund{p.isRefunded ? "ed" : ""}
                      </Button>
                    </List.Content>
                    <Icon name="money" />
                    <List.Content verticalAlign="middle">
                      {(p.isRefunded ? "[Refunded] " : "") +
                        moment(p.date).format("YYYY-MM-DD") +
                        " - " +
                        p.amountInCents / 100 +
                        "€"}
                    </List.Content>
                  </List.Item>
                ))}
            </List>
          )}
        </Segment>
      </div>
    );
  }
}
